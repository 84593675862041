export default {

	getRemainingVariants(selectedAttributes, products) {
		const names = Object.keys(selectedAttributes);
		if (names.length <= 0) return [...products];
		return products.filter(product => {
			let isRemaining = true;
			for (let i = 0; i < names.length; i++) {
				const productValue = product.attributes[names[i]];
				const selectedValue = selectedAttributes[names[i]];
				if (productValue !== selectedValue) {
					isRemaining = false;
				}
			}
			return isRemaining;
		});
	},

	// Given an attribute and list of products, return products that match that attribute
	getProductsByAttribute(attribute, products) {
		const newProducts = [];
		const { selector, value } = attribute;
		for (let i = 0; i < products.length; i++) {
			const attributes = products[i].attributes;
			const attributeNames = Object.keys(attributes);
			for (let j = 0; j < attributeNames.length; j++) {
				const name = attributeNames[j];
				if (name === selector && attributes[name] === value) {
					newProducts.push(products[i]);
				}
			}
		}
		return newProducts;
	},

	getProductByAttributes(attributes, products) {
		for (let i = 0; i < attributes.length; i++) {
			products = this.getProductsByAttribute(
				attributes[i].attributeDescription,
				attributes[i].attributeValue,
				products
			);
		}
		const product = products.length === 1 ? products[0] : null;
		return product;
	},

	getRemainingSelectorsMessage(remaining) {
		if (!remaining.length) return null;
		let msg = 'Please choose an available ' + remaining[0].selectorLabel;
		if (remaining.length >= 3) {
			for (let i = 1; i < remaining.length; i++) {
				if (i === remaining.length - 1) {
					msg += ', and ' + remaining[i].selectorLabel;
				} else {
					msg += ', ' + remaining[i].selectorLabel;
				}
			}
		} else if (remaining.length == 2) {
			msg += ' and ' + remaining[1].selectorLabel;
		}
		return msg;
	},

	getRemainingSelectors(remaining) {
		if (!remaining.length) return null;
		let remainingSelectors = [];
		remainingSelectors = [...remainingSelectors, remaining[0].selectorLabel];
		if (remaining.length >= 3) {
			for (let i = 1; i < remaining.length; i++) {
				if (i === remaining.length - 1) {
					remainingSelectors = [...remainingSelectors, remaining[i].selectorLabel];
				} else {
					remainingSelectors = [...remainingSelectors, remaining[i].selectorLabel];
				}
			}
		} else if (remaining.length == 2) {
			remainingSelectors = [...remainingSelectors, remaining[1].selectorLabel];
		}
		return remainingSelectors;
	},

	checkProductsForAttribute(products, attribute) {
		for (let i = 0; i < products.length; i++) {
			if (products[i].attributes[attribute.selector] === attribute.value) {
				return true;
			}
		}
	},

	updateSelectedAttribute(selectors, attribute) {
		for (let i = 0; i < selectors.length; i++) {
			if (selectors[i].selector !== attribute.selector) continue;
			for (let j = 0; j < selectors[i].values.length; j++) {
				const v = selectors[i].values[j];
				v.isSelected = v.value === attribute.value;
			}
		}
	},

	updateAttributeAvailability(selectorsToUpdate, productsToCheck) {
		for (let i = 0; i < selectorsToUpdate.length; i++) {
			for (let j = 0; j < selectorsToUpdate[i].values.length; j++) {
				const v = selectorsToUpdate[i].values[j];
				if (this.checkProductsForAttribute(productsToCheck, v)) {
					v.unavailable = false;
				} else {
					v.unavailable = true;
				}
			}
		}
	},

	updateSelectionErrors(selectorsToCheck) {
		// Check each selector to see if an unavailable attribute is still selected
		for (let i = 0; i < selectorsToCheck.length; i++) {
			if (selectorsToCheck[i].values.some(x => x.unavailable && x.isSelected)) {
				// If there is one, show the tooltip
				selectorsToCheck[i].showSelectionError = true;
				break;
			}
		}
	}

};