import { EMAIL_REGEX } from './regex.js';
/*============================================================
 == JSON
/============================================================*/

export function jsonCopy(val) {
	if (typeof val === 'undefined') return val;
	return JSON.parse(JSON.stringify(val));
}

export function jsonEquals(val, val2) {
	return JSON.stringify(val) === JSON.stringify(val2);
}

/*============================================================
 == Positioning
/============================================================*/

export function elementIsInViewport(el, leniency = 0) {
	if (typeof jQuery === "function" && el instanceof jQuery) {
		el = el[0];
	}

	const rect = el.getBoundingClientRect();

	return (
		rect.top >= 0 &&
		rect.left >= 0 &&
		rect.bottom - leniency <= (window.innerHeight || document.documentElement.clientHeight) &&
		rect.right - leniency <= (window.innerWidth || document.documentElement.clientWidth)
	);
}

export function getPageHeightAndWidth() {
	const body = document.body;
	const html = document.documentElement;
	const height = Math.max(
		body.scrollHeight,
		body.offsetHeight,
		html.clientHeight,
		html.scrollHeight,
		html.offsetHeight
	);
	const width = window.outerWidth;
	return { height, width };
}

export function elementOverflowsDocument(el) {
	if (typeof jQuery === "function" && el instanceof jQuery) {
		el = el[0];
	}
	const max = getPageHeightAndWidth();
	return (
		el.offsetTop < 0 ||
		el.offsetTop + el.clientHeight > max.height ||
		el.offsetLeft < 0 ||
		el.offsetLeft + el.clientWidth > max.width
	);
}

// get the left and top offset of a DOM block element
// returns {x: number, y: number}
export function getPageOffset(el) {
	const rect = el.getBoundingClientRect(),
		scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
		scrollTop = window.pageYOffset || document.documentElement.scrollTop;
	return {
		y: rect.top + scrollTop,
		x: rect.left + scrollLeft
	};
}

export function validateEmail(email) {
	return EMAIL_REGEX.test(String(email).toLowerCase());
}

// camelCase all keys of the given object
export function keysToCamelCase(obj) {
	if (typeof obj !== 'object') return obj;

	const newobj = {};
	const keys = Object.keys(obj);
	for (let i = 0; i < keys.length; i++) {
		const key = keys[i];
		if (obj[key] == null) continue;
		if (Array.isArray(obj[key])) {
			newobj[camelize(key)] = obj[key].map(el => keysToCamelCase(el));
		} else if (typeof obj[key] === 'object') {
			newobj[camelize(key)] = keysToCamelCase(obj[key]);
		} else {
			newobj[camelize(key)] = obj[key];
		}
	}
	return newobj;
}

// convert any string to camelCase string
function camelize(str) {
	return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
	  	return index == 0 ? word.toLowerCase() : word.toUpperCase();
	}).replace(/\s+/g, '');
}

/*============================================================
 == Display types
/============================================================*/

export function isRetinaDevice() {

	if (window.devicePixelRatio > 1) {
		return true;
	}

	const mediaQuery = "(-webkit-min-device-pixel-ratio: 1.5),\
		(min--moz-device-pixel-ratio: 1.5),\
		(-o-min-device-pixel-ratio: 3/2),\
		(min-resolution: 1.5dppx)";

	return window.matchMedia && window.matchMedia(mediaQuery).matches;
};

export function stringIsNullOrWhiteSpace(input) {
	if (typeof input === 'undefined' || input == null) return true;
	return input.replace(/\s/g, '').length < 1;
}

export function checkWebpSupport(callback) {
	const img = new Image();
	img.onload = function () {
		callback(true);
	};
	img.onerror = function () {
		callback(false);
	};
	img.src = "data:image/webp;base64,UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA";
}

/**
 * Add commas to any numeric string without adding decimal places
 * 
 * @param {*} string 
 * @returns comma separated numeric string
 */
export function addCommas(string) {
	return string.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};