let listener;

export default {
	update: (el, binding) =>  {
		listener = e => {
			if (e.target !== el && !el.contains(e.target)) {
				binding.value();
			}
		};
		document.addEventListener('click', listener);
	},
	unbind() {
		document.removeEventListener('click', listener);
	}
};