Blain.AccountHelpers = {

	initDeleteWithConfirm: function (obj) {

		const $confirmModal = $("#confirmModal");
		const $confirmProgress = $("#confirmProgress");
		const $confirmTextContainer = $("#confirmTextContainer");
		const $confirmText = $("#confirmText");
		const $confirmNo = $("#confirmNo");
		const $confirmYes = $("#confirmYes");
	
		obj.deleteLinkObject.off("click.del").on("click.del", function (e) {
			const ajaxData = {};
			for (const prop in obj.dataProperties) {
				if (obj.dataProperties.hasOwnProperty(prop)) {
					ajaxData[prop] = $(this).attr("data-" +  obj.dataProperties[prop]);
				}
			}
			e.preventDefault();
			$confirmText.text(obj.modalText);
	
			$confirmNo.off("click").on("click", function (ev) {
				ev.preventDefault();
				if (obj.onNo && typeof (obj.onNo) === "function") {
					obj.onNo();
				}
				if (obj.onDecide && typeof (obj.onDecide) === "function") {
					obj.onDecide();
				}
				$confirmModal.blainModal("hide");
			});
	
			$confirmYes.off("click").on("click", function (ev) {
				ev.preventDefault();
				if (obj.onYes && typeof (obj.onYes) === "function") {
					obj.onYes();
				}
				if (obj.onDecide && typeof (obj.onDecide) === "function") {
					obj.onDecide();
				}
				$confirmTextContainer.hide();
				$confirmProgress.show();
				$.ajax({
					type: "POST",
					url: `/account/${obj.urlRoute}`,
					dataType: "json",
					contentType: "application/json",
					data: JSON.stringify(ajaxData),
					error: function (xhr) {
						const errorObj = $.parseAjaxError(xhr);
						$.globalAjaxError(errorObj);
					},
					success: function (data) {

						if (!data.Success) {
							if (!data.CurrentSession && !data.CustomerLoggedIn) {
								window.location = "/account/verify/?refUrl=" + encodeURIComponent(window.location.pathname);
								return;
							}
						}

						for (const id in obj.partialViews) {
							if (obj.partialViews.hasOwnProperty(id)) {
								$("#" + id).html(data[obj.partialViews[id]]);
							}
						}
						obj.callbackFunction(data);
					},
					complete: function () {
						$confirmModal.blainModal("hide");
					}
				});
			});
			$confirmTextContainer.show();
			$confirmProgress.hide();
			if (obj.beforeConfirm && typeof (obj.beforeConfirm) === "function") {
				obj.beforeConfirm();
			}
			$confirmModal.blainModal("show");
		});
	},

};