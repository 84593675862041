export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const US_ZIP_REGEX = /(^\d{5}$)|(^\d{5}-\d{4}$)/;

export const PO_BOX_REGEX = /[p](ost)?[\.|\/]*\s*o(ffice)?\.*\s*box/i; ///[p](ost)?[\.|\/]*\s*[o|0](ffice)?\.*\s*[b|\d]([o|0]x)?/i;

export const NUMERIC_REGEX = new RegExp(/^\d+$/);

export const CURRENCY_REGEX = new RegExp(/^\d+(\.\d{2})?$/);

// From jQuery validate phoneUS method
export const PHONE_REGEX = /^(\+?1)?(\([2-9]([02-9]\d|1[02-9])\)|[2-9]([02-9]\d|1[02-9]))-?[2-9]([02-9]\d|1[02-9])-?\d{4}$/;

export const MASTERCARD_REGEX = /^5[1-5]|^(222[1-9]|22[3-9]\d|2[3-6]\d{2}|27[0-1]\d|2720)/;

export const VISA_REGEX = /^(4)/;

export const AMEX_REGEX = /^(3[47])/;

export const DISCOVER_REGEX = /^(6011)/;