const focus = {
	inserted: function(el, binding) {
		// Focus the element
		if ((!binding.hasOwnProperty('value')) || binding.value) {
			el.focus();
		}
	},
	update: function(el, binding) {
		if ((!binding.hasOwnProperty('value')) || binding.value) {
			const value = binding.value;
			if (value) {
				Vue.nextTick(function() {
					el.focus();
				});
			}
		}
	}
};

export default focus;