import { getFocusableElements } from '../utilities/dom.ts';

let prevFocusedElement;
let isEventAdded = false;

function listener(e) {
	const TAB = 9;
	if (e.keyCode !== TAB) {
		return;
	}

	const focusable = getFocusableElements(this);
	const firstFocusable = focusable[0];
	const lastFocusable = focusable[focusable.length - 1];

	if (e.shiftKey) {
		if (document.activeElement === firstFocusable) {
			e.preventDefault();
			lastFocusable.focus();
		}
	} else {
		if (document.activeElement === lastFocusable) {
			e.preventDefault();
			firstFocusable.focus();
		}
	}
}

function handler(el, shouldTrapFocus) {
	if (shouldTrapFocus && !isEventAdded) {
		prevFocusedElement = document.activeElement;
		el.addEventListener('keydown', listener);
		isEventAdded = true;
	} else if (!shouldTrapFocus && isEventAdded) {
		if (prevFocusedElement) prevFocusedElement.focus();
		el.removeEventListener('keydown', listener);
		isEventAdded = false;
	}
}

const trapFocus = {
	bind(el, binding) {
		handler(el, binding.value);
	},
	componentUpdated(el, binding) {
		handler(el, binding.value);
	},
	unbind(el, _) {
		handler(el, false);
	}
};

export default trapFocus;